




import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Positive: () => import('./Positive.vue'),
    Negative: () => import('./Negative.vue')
  }
})
export default class Outcome extends Vue {
private componentName = ''

private created (): void {
  if (this.$route.params.type === '1') this.componentName = 'Negative'
  else this.componentName = 'Positive'
}
}
